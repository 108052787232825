import { Outlet } from "react-router-dom";
import { Link } from 'react-scroll';
import { useEffect, useState } from "react";
import '../css/global.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Layout = () => {

    useEffect(() => {
        const resizeHeaderOnScroll = () => {
          const distanceY = window.scrollY || document.documentElement.scrollTop;
          const shrinkOn = 100;
          const headerEl = document.getElementById("navbar1");
          const headerEl2 = document.getElementById("mouse");
    
          if (distanceY > shrinkOn) {
            headerEl.classList.add("nav-border");
            headerEl2.classList.add("hide-mouse");
          } else {
            headerEl.classList.remove("nav-border");
            headerEl2.classList.remove("hide-mouse");
          }
        };
    
        window.addEventListener("scroll", resizeHeaderOnScroll);
    
        return () => {
          window.removeEventListener("scroll", resizeHeaderOnScroll);
        };
      }, []);

      const [isChecked, setIsChecked] = useState(false);

      const handleToggle = () => {
        setIsChecked(!isChecked);
      };

  return (
    <>
    <div className="wrapper">
        <nav className="navbar" id="navbar1">
          <div className="toggler-hold">
            <input type="checkbox" id="toggler" onChange={handleToggle} />
            <label for="toggler"><FontAwesomeIcon icon={faBars} /></label>
          </div>
            <ul className={`nav-links ${isChecked ? 'enable-menu' : ''}`}>
                 <li className="nav-item">
                  <a href="#">HOME</a>
                </li>
                {/*</li>
                <li className="nav-item">
                <Link activeClass="active"
                          to="buy-hat"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          onClick={handleToggle} 
                          className="menu-item">
                          ACHIEVEMENTS
                    </Link>
               */}
                <li className="nav-item">
                  <a href="https://hatsolana.com/#/leaderboard">LEADERBOARD</a>
                 </li>
                <li className="nav-item">
                  <a target="_blank" href="https://hatsolana.com/games/game.html">GAME</a>
                 </li>
                <li className="nav-item"></li>
            </ul>
        </nav>
        <Outlet />
    </div>
    </>
  )
};

export default Layout;