import GLOBE from 'vanta/dist/vanta.globe.min';
import { useState, useRef, useEffect } from 'react';
import TechnologyList from './Technologies';

import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const customAnimation2 = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const customAnimation3 = keyframes`
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Home = () => {

    return (
    <>
    <header className="header" id="header">
        <div className="header-text">
            <div className="header-text__content">
                <Reveal keyframes={customAnimation3} triggerOnce>
                    <h1>WHERE IS YOUR <br/><b>$HAT?</b></h1>
                </Reveal>  
                <Reveal keyframes={customAnimation2} triggerOnce>
                    <a className='button-53' href="https://jup.ag/swap/SOL-HAT" target='blank'>BUY NOW</a>
                </Reveal>
                <div className='socials'>
                  <Reveal keyframes={customAnimation3} triggerOnce>
                      <a target='blank' href="https://t.me/TheHatCommunity"><img alt="telegram" src="telegramlogo.png" /></a>
                  </Reveal>
                  <Reveal keyframes={customAnimation3} triggerOnce>
                      <a target='blank' href="https://twitter.com/HatSolana"><img alt="telegram" src="xlogo.png" /></a>
                  </Reveal>
                  <Reveal keyframes={customAnimation3} triggerOnce>
                      <a target='blank' href="https://www.dextools.io/app/en/solana/pair-explorer/3ZcoAdmvQtX3itFtHwR946NhznQh92Eq9hDhHJtGP6As?t=1711754062883"><img alt="dextools" src="dtlogo.png" /></a>
                  </Reveal>
                  {/*<Reveal keyframes={customAnimation3} triggerOnce>
                      <a target='blank' href="https://jup.ag/swap/SOL-HAT"><img alt="jupiter" src="juplogo.png" /></a>
                  </Reveal>*/}
                  <Reveal keyframes={customAnimation3} triggerOnce>
                      <a target='blank' href="https://moontok.io/coins/hat-7"><img alt="moontok" src="moontoklogo.png" /></a>
                  </Reveal>
                  <Reveal keyframes={customAnimation3} triggerOnce>
                      <a target='blank' href="https://dexscreener.com/solana/3zcoadmvqtx3itfthwr946nhznqh92eq9hdhhjtgp6as"><img alt="dexscreener" src="dslogo.png" /></a>
                  </Reveal>
                </div>
                <p className='takeover'>COMMUNITY TAKEOVER!</p>
            </div>
        </div>
        <div className="header-img">
            {/* ide lejet kepet tenni mobilon */}
            <img src="img/hair.png" />
        </div>
        <div class="mouse" id="mouse"></div>
    </header>
    {/*<section className="buy-hat" id="buy-hat">
        <h2>ACHIEVEMENTS</h2>
        <div className="experience">
            <Reveal keyframes={customAnimation} cascade damping={0.2} triggerOnce>
                <div className="exp-holder">
                    <div className="exp-icon1"></div>
                    <h3>COMMUNITY WITH A GOAL</h3>
                    <p>Our community, donning the hat of ambition, is relentlessly driven towards achieving our goals with unwavering determination.</p>
                </div>
                <div className="exp-holder">
                    <div className="exp-icon3"></div>
                    <h3>BELIEVE</h3>
                    <p>We believe in the transformative power of $HAT, symbolizing confidence, style, and the ability to express one's unique identity.</p>
                </div>
                <div className="exp-holder">
                    <div className="exp-icon2"></div>
                    <h3>LIFE CHANGING HAT</h3>
                    <p>HAT has made a difference for many; now, it's our chance to reciprocate its impact. Join the greatest community. HAT = Helping Achieve Together.</p>
                </div>
            </Reveal>
        </div>
    </section>*/}
    <section className="buy-hat" id="buy-hat">
        <h2>ACHIEVEMENTS</h2>
        <div className="achievements">
            <div className="achiev-icon"></div>
              <div className="achiev-text">
                <ul>
                  <li>🐕 Top 200 WIF holders airdropped</li>
                  <li>🌐 Listed on Jupiter's strict list</li>
                  <li>👥 1000+ followers on X</li>
                  <li>👥 500+ TG members</li>
                  <li>📝 Listed on CoinGecko</li>
                  <li>📝 Listed on Moontok</li>
                  <li>📝 Applied for CMC </li>
                  <li>💼 2000+ holders</li>
                  <li>✅ DEXTools updated</li>
                  <li>✅ BirdEye updated</li>
                  <li>✅ DEX Screener updated</li>
                </ul>
              </div>
        </div>
    </section>
    <section className='links' id="links">
    <h2>ADDRESSES</h2>
      <Reveal keyframes={customAnimation} triggerOnce>
        <div className='links-text'>
          <div className='links-text__text'>
            <p><b>COMMUNITY WALLET: <br/>5L3WzCo5DPkZMdpi2EJzWisZq9HTHujdhCK7CsGBNMHq<br/><br/></b></p>
            <p>What do all these 'WifHat' memes have in common? The $HAT! An accessory that is, in essence, the driving force behind all things meme-able!
                We are a community that knows the truth... THE $HAT STAYS ON!</p><br/>
            <p><b>CA:  HrqgFZipMFHXvN5nKvTUaCwuA3Tp2UGqcQzArRGAyQ22</b></p>
          </div>
        </div>
      </Reveal>
    </section>
    {/*<section className='memes' id='memes'>
    <h2>FUNNY MEMES</h2>
    <div class="meme-holder">
      <img src="memes/1.jpg"/><br/>
      <img src="memes/2.jpg"/><br/>
      <video controls>
        <source src="memes/3.mp4" type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video><br/>
      <video controls>
        <source src="memes/5.mov" type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video><br/>
      <img src="memes/6.jpg"/><br/>
      <img src="memes/7.jpg"/><br/>
      <img src="memes/8.jpg"/><br/>
    </div>
    </section>*/}
    </>
    );
  };
  
  export default Home;
