import React, { useState, useEffect } from 'react';
import './../css/leaderboard.css';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function Leaderboard() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [interval, setInterval] = useState('all');
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    fetch(`https://hatsolana.com/points/leaderboard.php?interval=${interval}`)
      .then(response => response.json())
      .then(data => setLeaderboardData(data))
      .catch(error => console.error('Error fetching leaderboard data:', error));
  }, [interval]);

  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval);
  };

  return (
    <div className='wrapper'>
        <div className='leaderContainer'>
          <div className='leaderboard'>
          <h1>Leaderboard</h1>
          <div>
              <button className='button-19' onClick={() => handleIntervalChange('24')}>Last 24 Hours</button>
              <button className='button-19' onClick={() => handleIntervalChange('1month')}>Last Month</button>
              <button className='button-19' onClick={() => handleIntervalChange('all')}>All Time</button>
          </div>
          <table>
              <thead>
              <tr>
                  <th>Rank</th>
                  <th>User</th>
                  <th>Total Points</th>
              </tr>
              </thead>
              <tbody>
              {leaderboardData.map((entry, index) => (
                  <tr key={index}>
                  <td style={{textAlign: "center"}}>{index + 1}</td>
                  <td>{entry.user}</td>
                  <td style={{textAlign: "center"}}>{entry.total_points}</td>
                  </tr>
              ))}
              </tbody>
          </table>
          </div>
          <div className='rules'>
            <h3 onClick={onOpenModal}>Click on this text to see the rules.</h3>
            <Modal open={open} onClose={onCloseModal} center>
              <h1>Rules</h1>
              <ul>
                <li>⭐ <b>1 point</b> for each raid (provide post links).</li>

                <li>⭐ <b>2 points</b> for a picture meme (quality matters).</li>

                <li>⭐ <b>3 points</b> for thoughtful Twitter posts about $HAT.</li>

                <li>⭐ <b>5 points</b> for Reddit posts about the project.</li>

                <li>⭐ <b>5 points</b> for active participation on Telegram for a week (admins must notice).</li>

                <li>⭐ <b>6 points</b> for shilling $HAT on a Twitter space.</li>

                <li>⭐ <b>7 points</b> for original, high-quality video memes.</li>

                <li>🌟 <b>20 points</b> for a high-quality TikTok/YouTube/Instagram post about $HAT.</li>

                <li>🌌 <b>200 points</b> if an influencer reposts your post (must mention $HAT).</li>

                <li>🌌 <b>250 points</b> for a TikTok/YouTube/Instagram video going viral (50k+ views).</li>

                <li>🌌 <b>400 points</b> for replies from Elon Musk, Toli, or Dogwifcoin.</li>
              </ul>
            </Modal>
          </div>
        </div>
    </div>
  );
}

export default Leaderboard;